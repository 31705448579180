import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import {
  cssMobileOnly,
  contentPadding,
  listLink,
} from "../styles/styleConstants"

interface ListItemProps {
  id?: string
  text: string
  link?: string
  iconUrl?: string
  category?: string
  indentLevel: number
  bold: boolean
  hovered: boolean
  setHovered: Function
}

export default function ListItem(props: ListItemProps) {
  return (
    <ListItemWrapper>
      <Link
        to={props.link}
        onMouseEnter={props.setHovered.bind(null, props.id)}
        onMouseLeave={props.setHovered.bind(null, null)}
      >
        <Item
          bold={props.bold}
          hovered={props.hovered}
          indent={props.indentLevel}
        >
          {props.iconUrl && <img src={props.iconUrl} alt={props.category} />}
          <div>{props.text}</div>
        </Item>
      </Link>
    </ListItemWrapper>
  )
}

ListItem.defaultProps = {
  id: null,
  text: "Placeholder",
  link: null,
  iconUrl: null,
  category: null,
  indentLevel: 0,
  bold: false,
  hovered: false,
  setHovered: () => {},
}

const ListItemWrapper = styled.li`
  display: block;
`

interface ItemProps {
  bold: boolean
  hovered: boolean
  indent: number
}

const hoverStyle = css`
  background: rgba(0, 0, 0, 0.04);
`

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: ${(props: ItemProps) => contentPadding + props.indent * 15}px;
  padding-right: ${contentPadding}px;
  color: ${listLink};
  transition: background-color 150ms;
  ${(p) => (p.hovered ? hoverStyle : "")}

  ${cssMobileOnly} {
    height: 44px;
  }

  @media (hover: hover) {
    &:hover {
      ${hoverStyle}
    }
    &:active {
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.04);
    }
  }

  img {
    width: 28px;
    height: 28px;
    vertical-align: middle;
  }

  div {
    margin-left: 5px;
    font-size: 16px;
    font-weight: ${(p) => (p.bold ? "bold" : "normal")};
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
