import React, { ReactElement } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import HomePageTitleTexts from "./HomePageTitleTexts"
import { cssMobileOnly } from "src/styles/styleConstants"

interface Props {
  numberOfStores?: number
}

export default function HomePageHeader({
  numberOfStores,
}: Props): ReactElement {
  const data = useStaticQuery(
    graphql`
      query {
        nyc: file(relativePath: { eq: "logo-nyc.png" }) {
          childImageSharp {
            fixed(width: 80, height: 80) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        la: file(relativePath: { eq: "logo-la.png" }) {
          childImageSharp {
            fixed(width: 80, height: 80) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Header>
      <Group>
        <LogoImg
          // @ts-ignore
          fixed={data?.[process.env.GATSBY_CITY]?.childImageSharp?.fixed}
          alt={`${process.env.GATSBY_CITY.toUpperCase()} Vintage Map`}
          loading="eager"
        />
        <HomePageTitleTexts numberOfStores={numberOfStores} />
      </Group>
    </Header>
  )
}

const Header = styled.header`
  padding: 30px 0 0;
  overflow: hidden;

  ${cssMobileOnly} {
    padding: 25px 0 0;
  }
`

const Group = styled.div`
  display: flex;
  align-items: center;

  --scale: calc(var(--content-width) / 375);
  width: 375px;
  margin-bottom: calc(-80px + 80px * var(--scale));
  overflow: visible;
  transform-origin: top left;
  transform: scale(var(--scale));
`

const LogoImg = styled(Img)`
  width: 80px;
  height: 80px;
  margin-right: 13px;
`
