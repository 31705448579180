import React from "react"
import styled, { css } from "styled-components"

import * as styleConstants from "../styles/styleConstants"

interface Props {
  translucent: boolean
  borders: boolean
  padded: boolean
  children: any
}

const UnorderedList = styled.ul`
  margin: 0;
  padding: 0 0 10px;
  list-style: none;

  ${(props: Props) =>
    !props.translucent &&
    css`
      background: white;
    `};
  ${(props) =>
    props.borders &&
    css`
      border-top: ${styleConstants.hairLine};
      border-bottom: ${styleConstants.hairLine};
    `};
  ${(props) =>
    props.padded &&
    css`
      padding-top: 2px;
      padding-bottom: 2px;
    `};
`

export default function List(props: Props) {
  return <UnorderedList {...props}>{props.children}</UnorderedList>
}

List.defaultProps = {
  translucent: true,
  striped: true,
  borders: false,
  padded: false,
}
