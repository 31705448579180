import React, { ReactElement, useRef } from "react"
import styled from "styled-components"

import { Area, Store } from "src/gatsby-node/data/dataSchemaV1"
import SegmentedControl from "../../components/SegmentedControl"
import HomeAreasList from "./HomeAreasList"
import HomeCategoryList from "./HomeCategoryList"
import SearchContainer from "./SearchContainer"
import useInViewObserver from "src/utils/useIsInViewObserver"
import Space from "src/components/Space"

interface NavigationProps {
  stores: {
    names: string[]
    byName: {
      [name: string]: Store
    }
  }
  geo: {
    levels: number
    areas: Area[]
  }
  tags: Array<string>
  activeTab?: string
  setImplicitCategory: Function
  setActiveTab: Function
}

export default function Navigation({
  stores,
  geo,
  tags,
  activeTab,
  setActiveTab,
}: NavigationProps): ReactElement {
  const { inViewRef, isInView } = useInViewObserver(true)
  const listBeginningRef = useRef(null)
  const onClick = (value) => {
    setActiveTab(value)
    if (listBeginningRef.current) {
      setTimeout(() => {
        if (listBeginningRef.current) {
          listBeginningRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
        }
      }, 1)
    }
  }

  return (
    <nav>
      <ScrollTrackingDiv ref={inViewRef} />
      <ScrollTrackingDiv2 ref={listBeginningRef} />
      <StickyArea>
        <BackgroundGradient isShown={!isInView} />
        <ControlArea>
          <SegmentedControl
            value={activeTab}
            items={[
              { value: "Area", onClick },
              { value: "Category", onClick },
              { value: "Name", onClick },
            ]}
          />
        </ControlArea>
      </StickyArea>

      <Space v={10} />

      {activeTab === "Area" && <HomeAreasList geo={geo} />}
      {activeTab === "Category" && <HomeCategoryList tags={tags} />}
      {activeTab === "Name" && <SearchContainer stores={stores} />}
    </nav>
  )
}

const StickyArea = styled.div`
  position: sticky;
  top: 0;
`

const BackgroundGradient = styled.div<{ isShown: boolean }>`
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 88px;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 0) 0%,
    rgba(254, 254, 254, 1) 20%,
    rgba(254, 254, 254, 1) 85%,
    rgba(254, 254, 254, 0) 100%
  );
  pointer-events: none;
  opacity: ${(p) => (p.isShown ? "1" : "0")};
`

const ControlArea = styled.div`
  position: relative;
  padding: 10px 18px 0;
`

const ScrollTrackingDiv = styled.div`
  position: relative;
  width: 0;
  height: 0;
`

const ScrollTrackingDiv2 = styled.div`
  position: relative;
  width: 0;
  height: 0;
  top: 1px;
`
