import React, { ReactElement } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  numberOfStores?: number
}

export default function HomePageTitleTexts({
  numberOfStores,
}: Props): ReactElement {
  const data = useStaticQuery(graphql`
    query HomePageTitleQuery {
      site {
        siteMetadata {
          title
          subTitle
          lastUpdate
        }
      }
    }
  `)
  const { siteMetadata } = data?.site

  return (
    <div>
      <PreTitle>The Complete</PreTitle>
      <Title>
        {siteMetadata.title}
        <Separator> – </Separator>
        <TitleDetail>
          {numberOfStores ? String(numberOfStores) : "All"} vintage, resale and
          thrift stores
        </TitleDetail>
      </Title>
      <LastUpdated>Updated {siteMetadata.lastUpdate}</LastUpdated>
    </div>
  )
}

const PreTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
`

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 27px;
  font-weight: bold;
  line-height: 1.1;
`

const Separator = styled.span`
  display: none;
`

const TitleDetail = styled.span`
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
`

const LastUpdated = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 1.7;
`
