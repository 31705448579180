import React, { useEffect } from "react"
import styled, { css } from "styled-components"

import ContentContainer from "src/containers/ContentContainer"
import Navigation from "./Navigation"
import Footer from "src/components/Footer"

import SEO from "src/components/SEO"
import ComingSoon from "src/components/ComingSoon"
import HomePageHeader from "src/views/home/HomePageHeader"
import Instagram from "src/views/home/Instagram"
import { Store, Area, PicksSet } from "src/gatsby-node/data/dataSchemaV1"
import { contentPadding, cssMobileOnly } from "src/styles/styleConstants"
import DownloadBadges from "./DownloadBadges"
import PageEndGradient from "src/components/PageEndGradient"
import PicksFeatured from "./PicksFeatured"
import Space from "src/components/Space"
import { Category } from "src/types"

export interface HomeProps {
  stores: {
    names: string[]
    byName: {
      [name: string]: Store
    }
  }
  geo: {
    levels: number
    areas: Area[]
  }
  picks: PicksSet[]
  tagsHighlighted: string[]
  tags: string[]
  activeTab?: string
  setImplicitCategory: (category: Category | null) => void
  setActiveTab: (tab?: string) => void
  height: number
}

const Home = (props: HomeProps) => {
  useEffect(() => {
    props.setImplicitCategory(null)
  }, [])

  return (
    <>
      <SEO />

      <ContentContainer>
        <ContentOrdering>
          <HeaderGroup>
            <HomePageHeader numberOfStores={props.stores?.names?.length} />
            <Space v={30} mobileV={35} />
          </HeaderGroup>

          {props.picks && props.picks.length >= 3 && (
            <PicksGroup>
              <PicksFeatured picks={props.picks} />
              <Space v={25} />
            </PicksGroup>
          )}

          <NavigationGroup>
            <Navigation {...props} />
          </NavigationGroup>

          <PageEndGradientGroup>
            <PageEndGradient />
            <Space v={10} />
          </PageEndGradientGroup>

          {process.env.GATSBY_CITY === "nyc" && (
            <InstagramGroup>
              <Instagram />
              <Space mobileV={20} />
            </InstagramGroup>
          )}

          <DownloadBadgesGroup>
            <DownloadBadges />
            <Space v={20} mobileV={35} />
          </DownloadBadgesGroup>
        </ContentOrdering>
      </ContentContainer>

      <Footer />

      {process.env.GATSBY_COMING_SOON === "1" && <ComingSoon />}
    </>
  )
}

export default Home

const hMargins = css`
  /* First fallback: If 'min' not supported */
  padding: 0 ${contentPadding}px;
  padding: 0 min(${contentPadding}px, 6vw);
`

const ContentOrdering = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderGroup = styled.div`
  order: 1;
  ${hMargins}
`

const InstagramGroup = styled.div`
  order: 3;
  ${hMargins}
  margin-bottom: 25px;

  ${cssMobileOnly} {
    order: 2;
    margin-bottom: 20px;
  }
`

const PicksGroup = styled.div`
  order: 3;
`

const NavigationGroup = styled.div`
  order: 3;
`

const PageEndGradientGroup = styled.div`
  order: 3;

  ${cssMobileOnly} {
    display: none;
  }
`

const DownloadBadgesGroup = styled.div`
  order: 4;
  ${hMargins}

  ${cssMobileOnly} {
    order: 1;
  }
`
