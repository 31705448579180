import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { PicksSet } from "src/gatsby-node/data/dataSchemaV1"
import {
  contentPadding,
  cssComputerOnly,
  primaryColor,
} from "src/styles/styleConstants"
import slugify from "src/utils/slugify"
import styled from "styled-components"

interface Props {
  picks: PicksSet[]
}

export default function PicksFeatured({ picks }: Props): ReactElement {
  // Limit to three
  const firstThreePicks = picks.slice(0, 3)
  if (firstThreePicks.length < 3) return null

  return (
    <ContainerDiv>
      <VerticalHeading>Picks by</VerticalHeading>
      <PeopleUl>
        {firstThreePicks.map(({ personName }) => {
          return (
            <PersonLink key={personName} to={`/picks/${slugify(personName)}`}>
              <PersonLi>
                <PersonImg
                  src={`/img/photo-${slugify(personName)}.jpg`}
                  alt={personName}
                />
                <NameDiv>{personName}</NameDiv>
                <InnerShadowDiv />
              </PersonLi>
            </PersonLink>
          )
        })}
      </PeopleUl>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  padding: 0 ${contentPadding}px;
  padding: 0 min(${contentPadding}px, 6vw);

  display: flex;
  user-select: none;
`

const VerticalHeading = styled.h3`
  margin: 0;
  padding: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-transform: uppercase;
  background: ${primaryColor};
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  width: 20px;
  height: 72px;
  flex-shrink: 0;
  text-indent: 5px;

  @media (max-width: 350px) {
    font-size: 3.4vw;
    height: 20.5vw;
  }
`

const PeopleUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
`

const PersonLink = styled(Link)`
  position: relative;
  width: 33.333%;
  margin-left: 10px;
  background: #eeeeee;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 350px) {
    margin-left: 2.9vw;
  }
`

const PersonLi = styled.li``

const NameDiv = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 2.9vw;
  font-weight: 500;
  text-align: center;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 5px 2px;

  ${cssComputerOnly} {
    font-size: 10px;
  }
`

const PersonImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 72px;
  object-fit: cover;
  color: transparent;
`

const InnerShadowDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 2px 12px rgba(0, 0, 0, 0.1);
`
