import { connect } from "react-redux"

import Home from "./Home"

import { setImplicitCategory } from "../../actions/storesActions"
import { setActiveTab, setSearchQuery } from "../../actions/navigationActions"
import { Category } from "src/types"

const mapStateToProps = (state, { pageContext }) => ({
  ...state.navigation,
  ...pageContext,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setImplicitCategory: (category?: Category) => {
      dispatch(setImplicitCategory(category))
    },
    setActiveTab: (activeTab?: string) => {
      dispatch(setActiveTab(activeTab))
    },
    setSearchQuery: (query?: string) => {
      dispatch(setSearchQuery(query))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
