import React, { ReactElement, useState } from "react"
import styled from "styled-components"

import { primaryColor, hairLine } from "../styles/styleConstants"

interface Props {
  items: Array<{
    label?: string
    value: string
    onClick: (value: string) => void
  }>
  value: string
}

export default function SegmentedControl<T>({
  items,
  value,
}: Props): ReactElement {
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null)
  const activeIndex = items.findIndex((item) => item.value === value)
  let hoverOffset = 0
  if (hoveredIndex != null) hoverOffset = hoveredIndex - activeIndex

  return (
    <Tabs>
      <InnerDiv>
        <ActiveTabBackgroundDiv
          activeIndex={activeIndex}
          hoverOffset={hoverOffset}
          total={items.length}
        />
        {items.map((item, index) => {
          const id = `sc-${index}`
          return (
            <Group
              key={item.value}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <input
                type="radio"
                name="segmented-control"
                id={id}
                value={item.value}
                checked={item.value === value}
                onChange={() => item.onClick(item.value)}
              />
              <label htmlFor={id} onTouchStart={() => {}}>
                {item.label ?? item.value}
              </label>
            </Group>
          )
        })}
      </InnerDiv>
    </Tabs>
  )
}

const Tabs = styled.ul`
  position: relative;
  height: 44px;
  border-radius: 22px;
  background: rgba(0, 0, 0, 0.01);
  box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.08);
  list-style: none;
  padding: 3px;
  margin: 0;
  user-select: none;
  overflow: hidden;
`

const InnerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const ActiveTabBackgroundDiv = styled.div<{
  activeIndex: number
  hoverOffset: number
  total: number
}>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: ${primaryColor};
  border-radius: 19px;
  transition: transform 200ms;

  ${(p) => `
    width: ${100 / p.total}%;
    transform: translateX(${p.activeIndex * 100 + p.hoverOffset}%) scaleX(${
    1 + Math.abs(p.hoverOffset) / 100
  });
`}
`

const Group = styled.div`
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;

  & > input {
    display: none;
  }

  & > input {
    &:checked {
      color: white;
      opacity: 1;
      background: ${primaryColor};
      border-radius: 19px;
      cursor: auto;
    }
  }

  & > label {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-size: min(16px, 4.5vw);
    font-weight: bold;
    line-height: 38px;
    transition: color 150ms, opacity 150ms;
    -webkit-tap-highlight-color: transparent;
  }

  & > input:not(:checked) ~ label {
    cursor: pointer;
    color: black;
    opacity: 0.6;

    @media (hover: hover) {
      :hover {
        opacity: 0.4;
      }
    }
  }

  & > input:checked ~ label {
    color: white;
    opacity: 1;
  }
`
