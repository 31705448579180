import React, { useState, useRef, useCallback, useEffect } from "react"
import isBrowser from "./isBrowser"

const useInViewObserver = (initial: boolean) => {
  const [isInView, setIsInView] = useState(initial)
  let setInViewRef

  if (isBrowser) {
    const observer = useRef(
      new IntersectionObserver(([entry]) => setIsInView(entry.isIntersecting))
    )

    setInViewRef = useCallback((element: any) => {
      if (element && observer.current) {
        observer.current.observe(element)
      }
    }, [])

    // Need to disable observer on unmount to avoid set state after unmount
    useEffect(() => {
      return () => observer.current.disconnect()
    }, [])
  }

  return { inViewRef: setInViewRef, isInView }
}

export default useInViewObserver
