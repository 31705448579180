import { connect } from "react-redux"

import Search from "src/components/Search"

import { setSearchQuery } from "src/actions/navigationActions"
import { setHovered } from "src/actions/storesActions"

const mapStateToProps = (state, props) => ({ ...state.navigation, ...props })

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchQuery: (query?: string) => {
      dispatch(setSearchQuery(query))
    },
    setHovered: (name?: string) => {
      dispatch(setHovered(name))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
