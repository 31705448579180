import React, { ReactElement } from "react"

import List from "src/components/List"
import ListItem from "src/components/ListItem"
import { categoryPath } from "src/utils/paths"
import { Area } from "src/gatsby-node/data/dataSchemaV1"

interface Props {
  geo: {
    levels: number
    areas: Area[]
  }
}

export default function HomeAreasList({ geo }: Props): ReactElement {
  // Support one or two levels for now
  if (geo.levels === 2) {
    return (
      <List>
        {geo.areas.map((borough) => {
          return (
            <div key={borough.name}>
              <ListItem
                text={borough.name}
                link={categoryPath(borough.name)}
                bold={true}
              />
              {borough.areas.map((neighborhood) => {
                return (
                  <ListItem
                    key={`neighborhood-${neighborhood.name}`}
                    text={neighborhood.name}
                    link={categoryPath(neighborhood.name)}
                    indentLevel={1}
                  />
                )
              })}
            </div>
          )
        })}
      </List>
    )
  } else {
    return (
      <List>
        {geo.areas.map((area) => {
          return (
            <div key={area.name}>
              <ListItem
                text={area.name}
                link={categoryPath(area.name)}
                bold={false}
              />
            </div>
          )
        })}
      </List>
    )
  }
}
