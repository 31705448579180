import React, { ReactElement } from "react"

import List from "src/components/List"
import ListItem from "src/components/ListItem"
import { iconAssetUrlForTags } from "src/utils/assetHelper"
import { categoryPath } from "src/utils/paths"

interface Props {
  tags: string[]
}

export default function HomeCategoryList({ tags }: Props): ReactElement {
  return (
    <List>
      {tags.map((item) => (
        <ListItem
          key={`tag-${item}`}
          text={item}
          link={categoryPath(item)}
          iconUrl={iconAssetUrlForTags([item])}
          category={item}
        />
      ))}
    </List>
  )
}
