import React, { ReactElement } from "react"
import styled from "styled-components"

import badgeAppStore from "src/images/badge-app-store.png"
import badgeGooglePlay from "src/images/badge-google-play.png"
import { cssComputerOnly } from "src/styles/styleConstants"

const links = {
  nyc: {
    ios: "https://apps.apple.com/us/app/nyc-vintage-map/id1538598359",
    android:
      "https://play.google.com/store/apps/details?id=app.gem.vintagemap.nyc",
  },
  la: {
    ios: "https://apps.apple.com/us/app/la-vintage-map/id1502599912",
    android:
      "https://play.google.com/store/apps/details?id=app.gem.vintagemap.la",
  },
}

export default function DownloadBadges(): ReactElement {
  const siteName = `${process.env.GATSBY_CITY.toUpperCase()} Vintage Map`
  return (
    <Group>
      <DownloadLink href={links[process.env.GATSBY_CITY].ios} target="_blank">
        <Badge src={badgeAppStore} alt={`${siteName} on App Store`} />
      </DownloadLink>
      <DownloadLink
        href={links[process.env.GATSBY_CITY].android}
        target="_blank"
      >
        <Badge src={badgeGooglePlay} alt={`${siteName} on Google Play`} />
      </DownloadLink>
    </Group>
  )
}

const Group = styled.div`
  display: flex;
  width: 100%;
  user-select: none;

  ${cssComputerOnly} {
    width: 85%;
  }

  & > *:first-child {
    margin-right: 10px;
  }
`

const DownloadLink = styled.a`
  display: block;
`

const Badge = styled.img`
  width: 100%;
  height: auto;
`
