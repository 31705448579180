import React, { useState } from "react"
import styled from "styled-components"

const Note = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const ZikretButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
`

const ComingSoon: React.FC<{}> = () => {
  const [isHidden, setIsHidden] = useState(true)

  if (isHidden === false) return null

  return (
    <Note>
      Coming Soon!
      <ZikretButton onClick={() => setIsHidden(false)} />
    </Note>
  )
}

export default ComingSoon
