export const setActiveTab = (activeTab?: string) => {
  return {
    type: "SET_ACTIVE_TAB",
    activeTab,
  }
}

export const setSearchQuery = (query?: string) => {
  return {
    type: "SET_SEARCH_QUERY",
    query,
  }
}

export const showPrintDialog = () => {
  return {
    type: "SHOW_PRINT_DIALOG",
  }
}

export const hidePrintDialog = () => {
  return {
    type: "HIDE_PRINT_DIALOG",
  }
}

export const showShareDialog = () => {
  return {
    type: "SHOW_SHARE_DIALOG",
  }
}

export const hideShareDialog = () => {
  return {
    type: "HIDE_SHARE_DIALOG",
  }
}
