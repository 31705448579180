import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import isTouchDevice from "../utils/isTouchDevice"

import StandardContent from "./StandardContent"
import List from "./List"
import ListItem from "./ListItem"
import { storePath } from "../utils/paths"
import * as assetHelper from "../utils/assetHelper"
import { Store } from "src/gatsby-node/data/dataSchemaV1"

interface SearchProps {
  stores: {
    names: string[]
    byName: {
      [name: string]: Store
    }
  }
  storeHovered?: string
  setHovered: Function
  searchQuery: string
  setSearchQuery: Function
}

class Search extends React.Component<SearchProps> {
  render = () => {
    return (
      <SearchTabArea>
        <StandardContent style={{ marginBottom: 0 }}>
          <SearchField
            type="search"
            value={this.props.searchQuery}
            onChange={this.onChangeText}
            placeholder="Search"
            autoFocus={isTouchDevice() ? false : true}
            onFocus={this.cursorToEnd}
          />
        </StandardContent>
        {this.renderSearchResults()}
      </SearchTabArea>
    )
  }

  renderSearchResults = () => {
    const results = this.matchingStores()
    if (this.props.searchQuery.length > 0 && results.length === 0) {
      return <NoResults>No Results</NoResults>
    }
    return (
      <List>
        {results.map((storeName) => {
          const store = this.props.stores.byName[storeName]
          return (
            <ListItem
              key={storeName}
              id={storeName}
              text={storeName}
              link={storePath(storeName)}
              iconUrl={assetHelper.iconAssetUrlForTags(store.tags)}
              category={get(store, "tags[0]")}
              hovered={store.name === this.props.storeHovered}
              setHovered={this.setHovered}
            />
          )
        })}
      </List>
    )
  }

  matchingStores = (): string[] => {
    if (this.props.searchQuery.length < 1) {
      return []
    }
    const startsWith = []
    const includes = []
    const lowerCaseQuery = this.props.searchQuery.toLowerCase()
    this.props.stores.names.forEach((name) => {
      const lowerCaseName = name.toLowerCase()
      if (lowerCaseName.startsWith(lowerCaseQuery)) {
        startsWith.push(name)
      }
      if (lowerCaseName.includes(lowerCaseQuery)) {
        includes.push(name)
      }
    })
    // Dedup
    const resultSet = new Set([...startsWith, ...includes])
    // Limit to 40
    const results = [...resultSet].slice(0, 40)
    return results
  }

  onChangeText = (event: any) => {
    this.props.setSearchQuery(event.target.value)
  }

  setHovered = (id?: string) => {
    this.props.setHovered(id)
  }

  cursorToEnd = (event: any) => {
    const tempValue = event.target.value
    event.target.value = ""
    event.target.value = tempValue
  }
}

export default Search

const SearchTabArea = styled.div`
  min-height: 83vh;
`

const SearchField = styled.input`
  font-size: 18px;
  padding: 10px 10px;
  width: 100%;
  margin: 18px 0;
  border: 2px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: rgba(255, 255, 255, 0.5);

  ::placeholder {
    color: #aaaaaa;
  }
`

const NoResults = styled.div`
  line-height: 40px;
  margin-left: 26px;
  margin-bottom: 16px;
  opacity: 0.4;
`
