import React, { ReactElement } from "react"
import styled from "styled-components"

import { primaryColor } from "src/styles/styleConstants"
import InstagramSvg from "src/images/ui_instagram_32.svg"

export default function Instagram(): ReactElement {
  return (
    <ContainerA href="https://instagram.com/nycvintagemap" target="_blank">
      <Group>
        <Circle>
          <WhiteInstagramIcon />
        </Circle>
        <Texts>
          <Line1>NYC vintage events, sales and news</Line1>
          <Line2>
            Follow <Handle>@nycvintagemap</Handle>
          </Line2>
        </Texts>
      </Group>
    </ContainerA>
  )
}

const WhiteInstagramIcon = styled(InstagramSvg)`
  * {
    fill: white;
  }
`

const ContainerA = styled.a`
  display: block;
  overflow: hidden;
  color: black;
`

const Group = styled.div`
  display: flex;
  align-items: center;

  --scale: calc(var(--content-width) / 375);
  width: 375px;
  margin-bottom: calc(-44px + 44px * var(--scale));
  overflow: visible;
  transform-origin: top left;
  transform: scale(var(--scale));
`

const Circle = styled.div`
  width: 44px;
  height: 44px;
  background: ${primaryColor};
  border-radius: 22px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Texts = styled.div``

const Line1 = styled.div`
  font-size: 15px;
  font-weight: 600;
`

const Line2 = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const Handle = styled.span`
  font-weight: bold;
  color: ${primaryColor};
`
